import { Controller } from "@hotwired/stimulus";
import CoordinateObject from "../../javascript/lib/coordinate_object";
/* global google */

export default class extends Controller {
  static targets = [
    "searchInput",
    "searchContainer",
    "searchBackButton",
    "searchInputContainer",
    "searchButton",
  ];

  static values = {
    origin: Object,
    destination: Object,
    maxRadiusMeters: Number,
    countries: Array,
  };

  initialize() {
    const { lng, lat } = this.originValue;
    this.countries = this.countriesValue;
    this.radius = this.maxRadiusMetersValue;
    this.origin = new CoordinateObject(lat, lng);
    this.checkValue();
  }

  connect() {
    if (typeof (google) != "undefined"){
      this.initAutocomplete();
    }
  }

  get circle() {
    // Create a bounding circle in meters from the center point
    return new google.maps.Circle({ center: this.origin, radius: this.radius });
  }

  initAutocomplete() {
    const options = {
      componentRestrictions: { country: this.countries },
      fields: ["address_components", "geometry", "name"],
      bounds: this.circle.getBounds(),
      strictBounds: true,
    };
    this.autocomplete = new google.maps.places.Autocomplete(this.searchInputTarget, options);

    // ClickHandler for the button, needed to send the Directions API with it -
    // Does not work with Stimulus click events
    const onClickHandler = async () => {
      // Gets the autocomplete place values
      let place = this.autocomplete.getPlace();
      if (typeof place.address_components === "undefined") {
        const suggestion = await this.queryAutocomplete(place.name);
        if (suggestion) {
          place = suggestion;
        }
      }
      this.destinationValue = place?.geometry?.location;
      let detail = {
        destination: this.destinationValue
      }
      if(place?.formatted_address) {
        detail = {...detail, address:  place.formatted_address}
      }
      document.dispatchEvent(new CustomEvent("newDestination", { detail: detail }));
    };

    this.autocomplete.addListener("place_changed", onClickHandler.bind(this));
  }

  queryAutocomplete(input) {
    const { circle } = this;

    const service = new google.maps.places.AutocompleteService(
      this.searchInputTarget,
      {
        componentRestrictions: { country: this.countries },
      },
    );

    return service.getPlacePredictions({ input })
      .then((response) => this.getLocationByPlaceId(response.predictions[0]?.place_id).then(
        (location) => {
          const isLocationWithinCircle = circle
            .getBounds()
            .contains(location.results[0].geometry.location);
          if (isLocationWithinCircle) return location.results[0];
        },
      ).catch((error) => document.dispatchEvent(new CustomEvent("invalidDestination", { detail: { destination: null } }))));
  }

  getLocationByPlaceId(placeId) {
    const geocoder = new google.maps.Geocoder();
    return geocoder.geocode(
      {
        placeId,
      },
      (resp, status) => {
        if (status === "OK") {
          return resp[0];
        }
      },
    );
  }

  checkValue() {
    this.searchInputTarget.value === "" ? this.searchInputContainerTarget.classList.remove("is-filled") : this.searchInputContainerTarget.classList.add("is-filled")
  }

  clearSearch() {
    this.searchInputTarget.value = "";
    this.checkValue();
  }
}
