import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["funnyText", "submit"];
  static values = { duration: Number, funnyTexts: Array };

  connect() {
    this.showFunnyTextsAcrossDuration();
  }

  showFunnyTextsAcrossDuration() {
    const funnyTexts = this.funnyTextsValue;
    const durationMilliseconds = this.durationValue * 60000;
    const intervalDuration = durationMilliseconds / funnyTexts.length;

    funnyTexts.forEach((message, index) => {
      if (index === 0) {
        this.setFunnyText(message);
      } else {
        setTimeout(() => {
          this.setFunnyText(message);
        }, index * intervalDuration);

        setTimeout(() => {
          // goes to the show taxis page or shows an alert
          if (index === funnyTexts.length - 1) {
            this.submitTarget.click();
          }
        }, durationMilliseconds);
      }
    });
  }

  setFunnyText(text) {
    this.funnyTextTarget.textContent = text;
  }
}
