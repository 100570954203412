import {Controller} from '@hotwired/stimulus';
import intlTelInput from 'intl-tel-input';

export default class extends Controller {
  static targets = ["input", "hidden"];
  static values = {validationMessages: Object, wrap: String};

  connect() {
    this.itl = intlTelInput(this.inputTarget, {
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@19.2.3/build/js/utils.min.js",
      containerClass: this.wrapValue,
      preferredCountries: ["no"],
      useFullscreenPopup: false,
      autoPlaceholder: "aggressive",
      showSelectedDialCode: true,
      countrySearch: true
    });
  }

  disconnect() {
    this.itl = null;
    super.disconnect();
  }

  reset() {
    this.inputTarget.setCustomValidity("")
  }

  validate(event) {
    this.reset()
    this.updateHidden()
    if (!this.itl.isValidNumber()) {
      let errorCode = this.itl.getValidationError();
      // If error code is less than 0, it means the user has not entered anything
      // set error code to 5 to display the "invalid" message
      if (errorCode < 0) {
        errorCode = 5;
      }
      this.inputTarget.setCustomValidity(this.validationMessagesValue[errorCode])
    } else {
      this.reset()
    }
  }

  updateHidden() {
    this.hiddenTarget.value = this.itl.getNumber();
  }
}
